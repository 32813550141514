var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-form",
        { staticStyle: { margin: "40px auto 0" } },
        [
          _c(
            "result",
            {
              attrs: {
                title: "操作成功",
                "is-success": true,
                description: "预计两小时内到账",
              },
            },
            [
              _c(
                "div",
                { staticClass: "information" },
                [
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { sm: 8, xs: 24 } }, [
                        _vm._v("付款账户："),
                      ]),
                      _c("a-col", { attrs: { sm: 16, xs: 24 } }, [
                        _vm._v("ant-design@alipay.com"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { sm: 8, xs: 24 } }, [
                        _vm._v("收款账户："),
                      ]),
                      _c("a-col", { attrs: { sm: 16, xs: 24 } }, [
                        _vm._v("test@example.com"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { sm: 8, xs: 24 } }, [
                        _vm._v("收款人姓名："),
                      ]),
                      _c("a-col", { attrs: { sm: 16, xs: 24 } }, [
                        _vm._v("辉夜"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { sm: 8, xs: 24 } }, [
                        _vm._v("转账金额："),
                      ]),
                      _c("a-col", { attrs: { sm: 16, xs: 24 } }, [
                        _c("span", { staticClass: "money" }, [_vm._v("500")]),
                        _vm._v(" 元"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "action" }, slot: "action" },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary" }, on: { click: _vm.finish } },
                    [_vm._v("再转一笔")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { "margin-left": "8px" },
                      on: { click: _vm.toOrderList },
                    },
                    [_vm._v("查看账单")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }